import React from 'react';
import {
  FaLinkedin,
} from 'react-icons/fa';

const Footer = () => {
  return (
    <div id='Footer' className='mx-auto w-full text-black text-center bg-[#ebe9df] py-12'>
      <div>
        <h1 className='w-full text-xl font-bold'>Romina Seminario Coaching</h1>
        <p className='w-full text-lg font-regular'>Barcelona</p>
        <div className='w-full mx-auto justify-center flex my-2 px-6'>
          <a href='https://www.linkedin.com/in/romina-s-7505449/' target='_blank' rel='noreferrer' className='hover:text-[#7C9184] duration-300'><FaLinkedin size={30} /></a>
          
        </div>
        <h2 className='py-4 text-md text-light text-[#7C9184]'>© Romina Seminario | Barcelona 2023</h2>
      </div>
    </div>
  );
};

export default Footer;