import React from'react';
import Navbar from './components/Navbar';
import Intro from './components/Intro';
import Bienvenido from './components/Bienvenido';
import Coaching from './components/Coaching';
import Servicio from './components/Servicio';
import Mentoring from './components/Mentoring';
import Testimonios from './components/Testimonios';
import Sobremi from './components/Sobremi';
import Cita from './components/Cita';
import Certificados from './components/Certificados';
import Contacto from './components/Contacto';
import Footer from './components/Footer';
import BackTop from './components/BackTop';
import IntroVid from './components/IntroVid';

function App() {
  return (
    <div>
      
      <IntroVid />
      <Contacto />
      <Footer />
      
    </div>
  );
}

export default App;