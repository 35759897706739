import React from 'react';
import videoBg from '../images/Clouds.mp4'

      const Intro = () => {
        return (
          <div id='Intro' className='w-full h-screen text-center bg-[#7C9184] flex flex-col justify-center items-center'>
            <div className="overlay"></div>
        <video src={videoBg} autoPlay loop muted />
              <div className='content max-w-[800px] h-screen bg-text-center flex flex-col justify-center items-center'>
              <h1 className='md:text-4xl sm:text-3xl text-3xl text-center font-bold py-2 mb-4 text-black'>
            Romina Seminario Coaching
            </h1>
          
          <p className='md:text-xl sm:text-xl text-xl font-light text-white md:pl-4'>I support my clients in the process of reflecting upon and developing awareness of their limiting beliefs, thoughts and emotions as well as the impact they have on themselves and others. I partner with them to broaden their perspectives, enhance their interpersonal skills, manage emotions effectively, turn challenges into opportunities and make more intentional decisions that will take them to achieve their goals, at the personal and professional level.</p>
          <button className='bg-[#ebe9df] w-[140px] rounded-md font-medium my-6 mx-auto py-2 text-[#7C9184] hover:scale-105 duration-500 scroll-smooth'><a href='https://www.linkedin.com/in/romina-s-7505449/'>Discover More</a></button>
              </div>
          </div>
       
      );
    };
    
    export default Intro;