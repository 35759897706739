import React from 'react'

const Contacto = () => {
  return (
    <div name='Contacto' className='w-full flex justify-center items-center bg-[#ebe9df] py-12 px-6'>
        <form method='POST' action="https://getform.io/f/3dc20101-83be-4f5c-b44b-11c6acdabeeb" className='flex flex-col max-w-[600px] w-full'>
            <div className='pb-8 text-center'>
                <p className='md:text-4xl sm:text-3xl text-3xl font-bold inline text-black'>GET IN CONTACT</p>
                <p className='text-black py-4'>Book a discovery call as an opportunity for us to connect and to explore if we are a match for working together. </p>
            </div>
            <input className='p-2 rounded-md' type="text" placeholder='Name' name='nombre' />
            <input className='my-4 p-2 rounded-md' type="email" placeholder='Email' name='email' />
            <textarea className='p-2 rounded-md' name="mensaje" rows="10" placeholder='Message'></textarea>
            <button className='bg-[#7C9184] w-[140px] rounded-md font-medium my-6 mx-auto py-3 text-[#ebe9df] hover:scale-105 duration-500 scroll-smooth'>Send</button>
        </form>
    </div>
  )
}

export default Contacto;